import axios from "axios";
import store from "@/store";
import router from "../router";

//
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

const appInsights = new ApplicationInsights({
  config: {
    instrumentationKey: process.env.VUE_APP_APPINSIGHTS_KEY,
    // extensions: [customPlugin],
    /* ...Other Configuration Options... */
  },
});
appInsights.loadAppInsights();

axios.defaults.headers.common["Authorization"] = `Bearer ${localStorage.getItem(
  "access_token"
)}`;

axios.interceptors.request.use((config) => {
  // Adding Request Headers
  config.headers["Request-Origin"] = "portal";

  if (config?.params) {
    config.params.currency = localStorage.getItem("selectedCurrency");
  }

  return config;
});

// Response interceptor
axios.interceptors.response.use(
  (response) => {
    // Handle the response here
    appInsights.trackTrace("Vue js", response);
    return response;
  },
  (error) => {
    // Handle errors here
    appInsights.trackException(error?.response?.data?.message);

    return Promise.reject(error);
  }
);

function get(url, config = {}) {
  if (config?.params?.signal) {
    config.signal = config?.params?.signal;
  }

  return axios
    .get(url, config)
    .then((response) => response)
    .catch((error) => handleError(error, url));
}

function post(url, data, config = {}) {
  return axios
    .post(url, data, config)
    .then((response) => response)
    .catch((error) => handleError(error, url));
}

function put(url, data, config = {}) {
  return axios
    .put(url, data, config)
    .then((response) => response)
    .catch((error) => handleError(error, url));
}

function patch(url, data, config = {}) {
  return axios
    .patch(url, data, config)
    .then((response) => response)
    .catch((error) => handleError(error, url));
}

function delete_method(url, config = {}) {
  return axios
    .delete(url, config)
    .then((response) => response)
    .catch((error) => handleError(error, url));
}

function handleError(error) {
  if (error.name === "AbortError") {
    return; // Gracefully handle canceled requests
  }

  if (error.response?.status === 409) {
    store.dispatch("alerts/info", "Different store, Reloading ...");
    setTimeout(() => router.go(0), 700);
  } else if (error.response?.status === 401) {
    store.dispatch("logout");
  } else {
    if (error?.message !== "canceled") {
      store.dispatch(
        "alerts/error",
        error.response?.data?.message || error?.message
      );
    }
  }

  return Promise.reject(error);
}

export default { post, get, put, patch, delete_method };
