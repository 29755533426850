<template>
  <span
    class="status-badge"
    :class="status"
  />
</template>
<script>
export default {
  props: {
    status: {
      type: String,
      default() {
        return "";
      },
    },
  },
};
</script>
<style lang="sass" scoped>
.status-badge
    display: block
    border-radius: 100%
    background: black
    width: 12px
    height: 12px
    margin-right: 0.5rem

.active , .online
    background: #35af35 !important
.inactive
    background: #f0ae38 !important
.offline, .Offline
    background: #df4f4f !important

.pending_approval
  background: #C3B1E1 !important

.approved
  background: #87CEEB !important

.in_progress
  background: #FFCC80 !important

.rejected, .cancelled
  background: #fb8787 !important

.completed
  background: #20a390 !important

.rescheduled
  background: #c1bdbd !important
</style>
