import serviceADX from "@/helpers/service";

const state = {
  dropdownloading: false,
  loading: false,
  sessionloading:false,
  meta: {},
  sessionsMeta: {},
  list: [],
  sessionslist: [],
  dropDownlist: [],
  dropdownloadingTerminal: false,
  dropDownlistTerminal: []
};

const mutations = {
  SET_LIST_DROPDOWN_TERMINAL(state, value) {
    state.dropDownlistTerminal = value;
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
  SET_SESSION_LIST(state, payload) {
    state.sessionslist = payload;
  },
  SET_SESSION_META(state, data) {
    if (data.meta) {
      state.sessionsMeta.page = data.meta.current_page;
      state.sessionsMeta.itemsPerPage = data.meta.per_page;
      state.sessionsMeta.lastPage = data.meta.last_page;
      state.sessionsMeta.totalItems = data.meta.total;
    }
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LOADING_LIST(state, value) {
    state.loading = value;
  },
  UPDATE_FUEL_TANK(state, row) {
    const item = state.list.find((item) => item.id === row.id);
    Object.assign(item, row);
  },
  SET_LIST_DROPDOWN(state, value) {
    state.dropDownlist = value;
  },
  SET_SESSION_LOADING_LIST(state, value) {
    state.sessionloading = value;
  },
};

const actions = {

  dropDownListTerminal: (store, params) => {
    store.state.dropdownloadingTerminal = true;
    return serviceADX
      .get("fuel-management/terminals-dropdown", params)
      .then((data) => {
        store.commit("SET_LIST_DROPDOWN_TERMINAL", data.body);
        return data.body;
      })
      .finally(() => (store.state.dropdownloadingTerminal = false));
  },
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
  setSessionLoadingValue: (store, value) => {
    store.commit("SET_SESSION_LOADING_LIST", value);
  },
  dropDownList: (store, params) => {
    store.state.dropdownloading = true;
    return serviceADX
      .get("fuel-management/fuel-tank-dropdown", params)
      .then((data) => {
        store.commit("SET_LIST_DROPDOWN", data.body);
        return data.body;
      })
      .finally(() => (store.state.dropdownloading = false));
  },
  sessionsList: (store, params) => {
    store.state.sessionloading = true;
    return serviceADX
      .get("fuel-management/vehicle-fuel-sessions", params)
      .then((data) => {
        store.commit("SET_SESSION_LIST", data.body.data);
        store.commit("SET_SESSION_META", data.body);
        return data.body.data;
      })
      .finally(() => (store.state.sessionloading = false));
  },
  list: (store, params) => {
    store.state.loading = true;
    return serviceADX
      .get("fuel-management/fuel-tank", params)
      .then((data) => {
        store.commit("SET_LIST", data.body.data);
        store.commit("SET_META", data.body);
        return data.body.data;
      })
      .finally(() => (store.state.loading = false));
  },
  add: (store, { payload, filter }) => {
    store.state.loading = true;
    return serviceADX
      .post("fuel-management/fuel-tank", payload)
      .then(() => {
        store.dispatch("list", filter);
      })
      .finally(() => (store.state.loading = false));
  },
  update: (store, params) => {
    store.state.loading = true;
    return serviceADX
      .put(`fuel-management/fuel-tank/${params.id}`, params)
      .then((data) => {
        store.commit("UPDATE_FUEL_TANK", data.body);
        return data;
      })
      .finally(() => (store.state.loading = false));
  },
  destroy: (store, params) => {
    return serviceADX.destroy(`fuel-management/fuel-tank/${params.id}`);
  },

};

const getters = {
  getSessionList(state){
    return state.sessionslist;
  },
  getSessionMeta(state){
    return state.sessionsMeta;
  },
  getSessionLoading(state) {
    return state.sessionloading;
  },
  getLoading(state) {
    return state.loading;
  },
  getDropdownloading(state) {
    return state.dropdownloading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
  getDropDown(state) {
    return state.dropDownlist
  },
  getDropdownloadingTerminal(state) {
    return state.dropdownloadingTerminal;
  },
  getTerminalDropDown(state) {
    return state.dropDownlistTerminal
  }

};

const csmsChargingSession = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default csmsChargingSession;
