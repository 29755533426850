import service from "@/helpers/service";

const state = {
  loading: false,
  meta: {},
  list: [],
};

const mutations = {
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },
};

const actions = {
  list: (store, params) => {
    store.state.loading = true;
    return service
      .get("fuel-management/fuel-dispenser-dashboard-data", params)
      .then((data) => {
        store.commit("SET_LIST", data.body);
        // store.commit("SET_META", data.body);
        return data.body.data;
      })
      .finally(() => (store.state.loading = false));
  },
};

const getters = {
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getList(state) {
    return state.list;
  },
};

const fuelDispenserDashboard = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default fuelDispenserDashboard;
