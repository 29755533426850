import service from "@/helpers/service";

const state = {
  loading: false,
  meta: {},
  list: [],
  dropDownlistTerminal: [],
  dropdownloading: false,
  dropDownlistFuel: [],
  dropdownloadingFuel: false,
};

const mutations = {
  SET_LIST_DROPDOWN_FUEL(state, value) {
    state.dropDownlistFuel = value;
  },
  SET_META(state, data) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    }
  },
  SET_LIST(state, payload) {
    state.list = payload;
  },

  ADD_LIST(state, payload) {
    state.list.push(payload);
  },

  UPDATE_LIST(state, row) {
    const item = state.list.find((item) => item.id === row.id);
    Object.assign(item, row);
  },

  DELETE_LIST(state, payload) {
    state.list.splice(state.list.map((o) => o.id).indexOf(payload.id), 1);
  },
  SET_LOADING_LIST(state, value) {
    state.loading = value;
  },
  SET_LIST_DROPDOWN_TERMINAL(state, value) {
    state.dropDownlistTerminal = value;
  },
};

const actions = {
  dropDownListFuel: (store, params) => {
    store.state.dropdownloadingFuel = true;
    return service
      .get("fuel-management/fuel-tank-dropdown", params)
      .then((data) => {
        store.commit("SET_LIST_DROPDOWN_FUEL", data.body);
        return data.body;
      })
      .finally(() => (store.state.dropdownloadingFuel = false));
  },
  dropDownList: (store, params) => {
    store.state.dropdownloading = true;
    return service
      .get("fuel-management/terminals-dropdown", params)
      .then((data) => {
        store.commit("SET_LIST_DROPDOWN_TERMINAL", data.body);
        return data.body;
      })
      .finally(() => (store.state.dropdownloading = false));
  },
  list: (store, params) => {
    store.state.loading = true;
    return service.get(`fuel-management/dispensers`, params).then((data) => {
      store.state.loading = false;
      if (data.body?.meta) {
        store.commit("SET_META", data.body);
        store.commit("SET_LIST", data.body.data);
      } else {
        store.commit("SET_LIST", data.body);
      }
    });
  },

  add: (store, { payload, filter }) => {
    return service
      .post("fuel-management/dispensers", payload)
      .then((data) => {
        store.commit("ADD_LIST", data.body);
        store.dispatch("list", filter);
      })
      .finally(() => (store.state.loading = false));
  },

  update: (store, params) => {
    return service
      .put(`fuel-management/dispensers/${params.id}`, params)
      .then((res) => {
        store.commit("UPDATE_LIST", res.body);
        return res;
      });
  },

  destroy: (store, params) => {
    return service
      .destroy(`fuel-management/dispensers/${params.id}`)
      .then(() => {
        store.commit("DELETE_LIST", params);
      });
  },
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING_LIST", value);
  },
};

const getters = {
  getDropdownloadingFuel(state) {
    return state.dropdownloadingFuel;
  },
  getLoading(state) {
    return state.loading;
  },
  getMeta(state) {
    return state.meta;
  },
  getDropDownFuel(state) {
    return state.dropDownlistFuel;
  },
  getList(state) {
    return state.list;
  },
  getTerminalDropDown(state) {
    return state.dropDownlistTerminal;
  },
  getDropdownloading(state) {
    return state.dropdownloading;
  },
};

const dispenser = {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};

export default dispenser;
