import service from "../services/vehicle-service";
import pService from "@/helpers/service";
const state = {
  isLoadingVehicles: false,
  listVehicles: [],
  listGroups: [],
  vehicle: {},
  meta: {},
  groupMeta: {},
  editedDevice: {},
  isVisibleEditDialog: false,
  isLoadingVehicle: false,
  isLoadingGroup: false,
  isThirdParty: false,
  thirdPartyList: [],
  isLoadingGroupListing: false,
  groupListVehicles: [],
};

const mutations = {
  SET_VEHICLES(state, listVehicles) {
    state.listVehicles = listVehicles;
  },
  SET_GROUPS_LISTING(state, payload) {
    state.groupListVehicles = payload;
  },
  SET_GROUPS(state, listGroups) {
    state.listGroups = listGroups;
  },
  SET_META(state, data = { meta: null }) {
    if (data.meta) {
      state.meta.page = data.meta.current_page;
      state.meta.itemsPerPage = data.meta.per_page;
      state.meta.lastPage = data.meta.last_page;
      state.meta.totalItems = data.meta.total;
    } else {
      state.meta = {};
    }
  },
  SET_GROUP_META(state, data = { groupMeta: null }) {
    if (data.meta) {
      state.groupMeta.page = data.meta.current_page;
      state.groupMeta.itemsPerPage = data.meta.per_page;
      state.groupMeta.lastPage = data.meta.last_page;
      state.groupMeta.totalItems = data.meta.total;
    } else {
      state.groupMeta = {};
    }
  },

  ADD_VEHICLE(state, vehicle) {
    state.listVehicles.push(vehicle);
  },
  IS_VISIBLE_EDIT_DIALOG(state, value) {
    state.isVisibleEditDialog = value;
  },

  SET_VEHICLE(state, vehicle) {
    state.vehicle = vehicle;
  },

  EDITED_VEHICLE(state, vehicle) {
    state.editedVehicles = vehicle;
  },
  UPDATE_VEHICLE(state, vehicle) {
    state.listVehicles = state.listVehicles.map((r) =>
      r.id == vehicle.id ? vehicle : r
    );
  },
  DELETE_VEHICLE(state, vehicle) {
    state.listVehicles.splice(
      state.listVehicles.map((o) => o.id).indexOf(vehicle.id),
      1
    );
  },

  SET_THIRD_PARTY_LIST(state, payload) {
    state.thirdPartyList = payload;
  },
  SET_LOADING(state, value) {
    state.isLoadingVehicles = value;
  },
  SET_LOADING_GROUP(state, value) {
    state.isLoadingGroupListing = value;
  },
};

const actions = {
  setLoadingValue: (store, value) => {
    store.commit("SET_LOADING", value);
  },
  setGroupLoadingValue: (store, value) => {
    store.commit("SET_LOADING_GROUP", value);
  },
  list: (store, params) => {
    store.state.isLoadingVehicles = true;
    service.list(params).then((data) => {
      store.commit("SET_VEHICLES", data.body.data);
      store.commit("SET_META", data.body);
      store.state.isLoadingVehicles = false;
    });
  },
  getVehiclesMQTT: (store, params) => {
    store.state.isLoadingVehicles = true;
    pService.get(`vehicle/get-vehicles-mqtt-data`, params).then((data) => {
      const d = data.body.map((r) => ({ ...r, selected: true }));
      store.commit("SET_VEHICLES", d);
      store.state.isLoadingVehicles = false;
    });
  },
  vehiclesList: (store, params) => {
    store.state.isLoadingVehicles = true;
    return pService.get("/vehicle/get-vehicles", params).then((data) => {
      store.commit("SET_VEHICLES", data.body);
      store.state.isLoadingVehicles = false;
    });
  },
  vehicleDropDown: (store, params) => {
    store.state.isLoadingVehicles = true;
    return pService
      .get("vehicle/get-dropdown-with-filters", params)
      .then((data) => {
        store.commit("SET_VEHICLES", data.body);
        store.state.isLoadingVehicles = false;
        return data.body;
      });
  },
  groupDropDown: (store, params) => {
    store.state.isLoadingGroup = true;
    return pService
      .get("vehicle/vehicle-group-dropdown", params)
      .then((data) => {
        store.commit("SET_GROUPS", data.body);
        store.state.isLoadingGroup = false;
      });
  },
  vehicleGroupListing: (store, params) => {
    store.state.isLoadingGroupListing = true;
    return pService.get("vehicle/vehicle-group", params).then((data) => {
      if (data.body?.meta) {
        store.commit("SET_GROUPS_LISTING", data.body.data);
        store.commit("SET_GROUP_META", data.body);
      } else {
        const d = [];
        data.body.forEach((el) => {
          el.selected = true;
          const vehicles = [];
          el.vehicles.forEach((ele) => {
            vehicles.push({ ...ele, selected: true });
          });
          el.vehicles = vehicles;
          d.push(el);
        });

        store.commit("SET_GROUPS_LISTING", data.body);
      }
      store.state.isLoadingGroupListing = false;
    });
  },
  createVechicleGroup: (store, params) => {
    return pService.post("vehicle/vehicle-group", params).then(() => {
      // store.commit("SET_GROUPS_LISTING", data.body);
    });
  },
  updateVechicleGroup: (store, params) => {
    return pService
      .put(`vehicle/vehicle-group/${params.id}`, params)
      .then(() => {
        // store.commit("SET_GROUPS_LISTING", data.body);
      });
  },

  deleteGroup: (params) => {
    return pService
      .destroy(`vehicle/vehicle-group/${params.id}`, params)
      .then(() => {
        // store.commit("SET_GROUPS_LISTING", data.body);
      });
  },

  add: (store, params) => {
    return service.add(params).then((data) => {
      store.commit("ADD_VEHICLE", data.body);
      // if (filter.isAdmin) {
      //   if (filter.tenant_id) {
      //     store.commit("ADD_VEHICLE", data.body);
      //     store.dispatch("list", filter);
      //   }
      // } else {
      //   store.commit("ADD_VEHICLE", data.body);
      //   store.dispatch("list", filter);
      // }
    });
  },

  show: (store, vehicleId) => {
    store.state.isLoadingVehicle = true;
    return service.show(vehicleId).then((data) => {
      store.commit("SET_VEHICLE", data.body);
      store.state.isLoadingVehicle = false;
    });
  },

  update: (store, vehicle) => {
    return service.update(vehicle).then((vehicle) => {
      store.commit("UPDATE_VEHICLE", vehicle.body);
      // store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },

  destroy: (store, vehicle) => {
    return service.destroy(vehicle).then(() => {
      store.commit("DELETE_VEHICLE", vehicle);
    });
  },

  assignCustomers: (store, vehicle) => {
    return service.assignCustomer(vehicle).then((vehicle) => {
      store.commit("UPDATE_VEHICLE", vehicle.body);
      return vehicle.body;
      // store.dispatch("list", { page: 1, itemsPerPage: 30 });
    });
  },
  bulkAssignToBusiness: (store, vehicles) => {
    return service.bulkAssignToBusiness(vehicles);
  },

  thirdPartiesList: (store, params) => {
    store.state.isThirdParty = true;
    return service.thirdPartiesList(params).then((data) => {
      store.commit("SET_THIRD_PARTY_LIST", data.body.third_parties_list);
      store.state.isThirdParty = false;
    });
  },
  destroyGroup: (store, params) => {
    return pService.destroy(`vehicle/vehicle-group/${params.id}`);
  },
};

const getters = {
  getVehiclesList(state) {
    return state.listVehicles;
  },
  getGroupsList(state) {
    return state.listGroups;
  },
  getVehiclesMeta(state) {
    return state.meta;
  },
  geGroupMeta(state) {
    return state.groupMeta;
  },
  getIsLoadingVehicles(state) {
    return state.isLoadingVehicles;
  },
  getVehicle(state) {
    return state.vehicle;
  },

  isLoadingVehicle(state) {
    return state.isLoadingVehicle;
  },
  getIsLoadingGroup(state) {
    return state.isLoadingGroup;
  },

  isThirdParty(state) {
    return state.isThirdParty;
  },
  getThirdPartyList(state) {
    return state.thirdPartyList;
  },
  getGroupListingLoader(state) {
    return state.isLoadingGroupListing;
  },
  getGroupListing(state) {
    return state.groupListVehicles;
  },
};

const vehicles = {
  namespaced: true,
  state,
  mutations,
  actions,
  getters,
};

export default vehicles;
